export const logTypes = [
  {
    label: '创建销售合同',
    value: 1,
  },
  {
    label: '销售合同审核',
    value: 2,
  },
  {
    label: '销售合同变更',
    value: 3,
  },
  {
    label: '销售合同变更审核',
    value: 4,
  },
  {
    label: '销售合同取消',
    value: 5,
  },
  {
    label: '销售合同完结',
    value: 6,
  },
  {
    label: '创建订单',
    value: 7,
  },
  {
    label: '创建发货单',
    value: 8,
  },
  {
    label: '取消发货单',
    value: 9,
  },
  {
    label: '订单付款',
    value: 10,
  },
  {
    label: '订单收货',
    value: 11,
  },
  {
    label: '订单售后',
    value: 12,
  },
  {
    label: '取消订单',
    value: 13,
  },
  {
    label: '订单售后收货',
    value: 14,
  },
  {
    label: '订单售后审核',
    value: 15,
  },
  {
    label: '订单收货',
    value: 16,
  },
];
